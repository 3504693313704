import {getRequest, postRequest, putRequest} from "./index";



export const getMenuByMerchantId = async (id: number) => {
    const data = await getRequest(`api/justeat/menu/${id}`);
    console.log('getMenuByMerchantId', data)
    return data as any;
}


export const setMenu = async (id: number, payload: any) => {
    const data = await postRequest(`api/justeat/menu/${id}`, payload);
    console.log(data)
    return data as any;
}

export const updateMenu = async (id: number, payload: any) => {
    const data = await putRequest(`api/justeat/menu/${id}`, payload);
    console.log(data)
    return data as any;
}

export const publishMenuByMerchantId = async (id: number) => {
    const data = await postRequest(`api/justeat/menu/${id}/publish`, {});
    console.log(data)
    return data as any;
}
