export const updateMenuWithSkus = (menu : any) => {
    return {
        ...menu,
        categories: updateCategoriesWithSkus(menu.categories),
    };
}

export const updateCategoriesWithSkus = (categories : any) => {
    return categories.map((category : any) => {
        if (category?.products?.length && category.products[0]?.sku) {
            const skus = category.products.map((item : any) => item.sku);
            return { ...category, products: skus }; // Replace products with SKUs
        }
        return category;
    });
}

export const categoriesToCSVArray = (categories : any) => {
    const categoriesWithSkus = updateCategoriesWithSkus(categories);
    const categoryCSV: Array<{category: string, sku: string}> = [];
    categoriesWithSkus.forEach((category : any) => {
        const products = category.products.map((sku : string) => ({ sku: sku.split('_')[0], category: category.name }));
        categoryCSV.push(...products);
    });
    return categoryCSV;
}
