import { FC, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import 'react-csv-importer/dist/index.css';
import { Divider } from "primereact/divider";
import { categoriesToCSVArray } from "./util";
import { updateMenu } from "../../../api/menus";

interface Props {
    merchantId: number;
    menu: any
    isOpen: boolean;
    closeModal: () => void;
    setCategories: (categories: any) => void;
}

const BulkMenuActionsModal: FC<Props> = ({merchantId, isOpen, menu, closeModal, setCategories}) => {
    const [menuCSV, setMenuCSV] = useState<any[]>([]);

    useEffect(() => {
        if(menuCSV.length === 0 && isOpen) {
            const thisMenu = categoriesToCSVArray(menu.categories);
            setMenuCSV(thisMenu);
        };
    }, [isOpen, menu.categories, menuCSV.length]);
    const headerCSV = [
        {label: 'Category', key: 'category'},
        {label: 'SKU', key: 'sku'},
    ];

    const dataHandler = async (rows: any[]) => {
        if(!merchantId) return;
        const newCategories: Array<string> = [];
        rows.forEach(({category}) => {
            if (newCategories.includes(category)) return;
            newCategories.push(category);
        });
        const newMenu = newCategories.map((cat) => {
            const filteredRows = rows.filter(({category}) => category === cat);
            const products = filteredRows.map(({sku}) => sku + '_' + merchantId);
            return {name: cat, products};
        })
        const updatedMenu = {
            categories: newMenu,
        }
        const res = await updateMenu(merchantId, updatedMenu);
        setCategories(res.categories);
        closeModal();
    };

    const header = <div className="flex justify-start items-center">
        <h2>Bulk Aktionen</h2>
    </div>;

    const footer = <div className="flex justify-between items-center">
        <CSVLink
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            data={menuCSV}
            headers={headerCSV}
            filename="menus.csv"
        >Download CSV</CSVLink>
        <Divider layout="vertical" />
        <button
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            onClick={closeModal}
        >Close</button>
    </div>;



    return <Dialog header={header} footer={footer} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <Importer 
            dataHandler={dataHandler} 
            onComplete={() => {}} 
            onClose={({ file, preview, fields, columnFields }) => {}}
        >
            <ImporterField name="category" label="Category" />
            <ImporterField name="sku" label="SKU" />
        </Importer>
    </Dialog>;
};
export default BulkMenuActionsModal;